<template>
  <div class="Feedback">
    <van-nav-bar
      @click-left="onClickLeft"
      left-text="返回"
      left-arrow
    />
    <div class="van-contact-edit">
      <van-field
        rows="5"
        autosize
        v-model="message"
        label="反馈"
        type="textarea"
        maxlength="300"
        placeholder="请输入反馈意见"
        show-word-limit
      />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">提交</van-button>
        </div>
    </div>
  </div>

</template>

<script>

export default {
  metaInfo: {
    title: '反馈',
  },
  name: 'Feedback',
  data () {
    return {
      message: ''
    }
  },
  methods: {
    ceshi() {
      this.$wx.checkJsApi({
        jsApiList: ['chooseImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function(res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          // console.log(res)
        }
      });
    }
  }
}
</script>

<style scoped>
>>> .van-icon {
  color: #00a29a;
}
>>> .van-nav-bar__text {
  color: #00a29a;
}
.van-button--primary {
  background-color: #00a29a;
  border: 1px solid #00a29a;
}
</style>
